import { Auth, Role } from "../interfaces/interfaces";
import { clearAccessToken } from "../redux/authSlice";
import store from "../redux/store";
import jwt_decode from "jwt-decode";

export function getAuthToken(): string | null {
  let token = store.getState().auth.accessToken;
  if (token && !isTokenExpired(token)) return token;

  try {
    const authData: string | null = localStorage.getItem("auth");
    if (authData) {
      const userAuth: Auth = JSON.parse(authData);
      if (userAuth && userAuth.token && !isTokenExpired(userAuth.token))
        return userAuth.token;
    }
  } catch (e) {
    console.error("Error parsing auth data from localStorage: ", e);
  }

  clearAllTokens();
  return null;
}

export function isAuthorized() {
  const authData: string | null = localStorage.getItem("auth");
  if (!authData) return false;
  const auth: Auth = JSON.parse(authData);
  let role: Role = Role[auth.role as keyof typeof Role];
  return role >= Role.Readers;
}
export function clearAllTokens() {
  localStorage.removeItem("me");
  store.dispatch(clearAccessToken());
}

export function isTokenExpired(token: string) {
  if (token) {
    try {
      const decoded: any = jwt_decode(token);
      return decoded.exp * 1000 < new Date().getTime();
    } catch (error) {
      console.error("Error decoding token", error);
    }
  }
}
