import React from 'react';
import { Box, Typography } from '@mui/material';
import { Verification } from '../../../interfaces/interfaces';

export interface CounterDeviationMessageProps {
    warning: Verification;
}


const CounterDeviationMessage: React.FC<CounterDeviationMessageProps> = (props: CounterDeviationMessageProps) => {
    const formatValue = (unformattedValue: number) => {
        // If the unformatted value has no decimals, return it as such but set the decimal pointer to . and separate thousands with blank space.
        let formattedValue = unformattedValue.toFixed(2)
        formattedValue = formattedValue.replace(',', '.');
        formattedValue = formattedValue.replace("/\B(?=(\d{4})+(?!\d)/g", '');
        return formattedValue;
    }

 const renderWarningText = () => {
    if(props.warning.message === "PREVIOUS_ESTIMATE_HIGHER") return (<div>Inmatat värde <strong>({formatValue(props.warning.value)})</strong> är lägre än beräknat värde.</div>);
    else if(props.warning.message === "PREVIOUS_READING_HIGHER") return (<div>Inmatat värde <strong>({formatValue(props.warning.value)})</strong> är lägre än tidigare avläst värde.</div>);
    else if(props.warning.message === "VALUE_OUT_OF_BOUNDS") {
        if(props.warning.counterMeterType === "Värmeväxlare")  
            return (<div>Inmatat värde ger en verkningsgrad <strong>({formatValue(props.warning.value)})</strong> som understiger förväntad <strong>({props.warning.deviation})</strong>.</div>);
        else if(props.warning.counterMeterType === "Temperatur")  
            return (<div>Inmatad temperatur <strong>({formatValue(props.warning.value)})</strong> avviker från förväntat intervall <strong>({props.warning.min} till {props.warning.max})</strong>.</div>);
        else if(props.warning.counterMeterType === "Temp, VV")  
            return (<div>Inmatad temperatur <strong>({formatValue(props.warning.value)})</strong> avviker från förväntat intervall <strong>({props.warning.min} till {props.warning.max})</strong>.</div>);
        else if(!props.warning.isConsumption)
            return (<div>Inmatat värde <strong>({formatValue(props.warning.value)})</strong> avviker från förväntat.</div>);
        else {
            if(props.warning.lastYearsConsumption < 0)
                return (<div>Inmatat värde <strong>({formatValue(props.warning.value)})</strong> avviker från förväntat.</div>);
            else 
                return (<div>Inmatat värde <strong>({formatValue(props.warning.value)}) </strong>  
            och föregående värde <strong>{formatValue(props.warning.previousValue)} </strong> 
            ger under aktuell period förbrukning <strong>{formatValue(props.warning.consumption)} </strong> 
            som avviker från föregående år <strong>({formatValue(props.warning.lastYearsConsumption)}) </strong>. Detta ger en avvikelse på <strong>({props.warning.difference.toFixed(2)}%)</strong>.</div>
                );
        }
    }
 }

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9', // Light grey background for subtle contrast
        padding: '16px', // Add padding for spacing
        borderRadius: '8px', // Rounded corners
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Optional: subtle shadow for depth
      }}
    >
      <Typography component={'span'} variant="body1">
        {renderWarningText()}
      </Typography>
    </Box>
  );
};

export default CounterDeviationMessage;