import { Typography } from "@mui/material";
import React from "react";
// A style component for displaying a heading and a bread text within a List component.

interface CounterDeviationTitleProps {
  counterId: number;
  counterName: string;
  counterNumbers: string[];
  counterType: string;
}
const CounterDeviationTitle: React.FC<CounterDeviationTitleProps> = (
  props: CounterDeviationTitleProps
) => {

  const renderName = () => {
    // If props.counterName is not null or empty string.
    if (props.counterNumbers?.length > 0) {
        if(props.counterName) 
        return `${props.counterNumbers.join(",")} / ${props.counterName}`;    
        else return `${props.counterNumbers.join(",")}`;
    }
    else if(props.counterName) return props.counterName;
    else return `SaveUp-ID: ${props.counterId}`
  }

  return (
    <div style={{margin: '8px'}}>
      <Typography variant="h6">{renderName()}</Typography>
      <Typography variant="body2">{props.counterType}</Typography>
    </div>
  );
};
export default CounterDeviationTitle;
