// authSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import saveupService from '../api/saveupService';
import { Auth, Me } from '../interfaces/interfaces';
import { handleResponse } from '../api/saveupClient';

interface AuthState {
  auth: Auth | null;
  loading: boolean;
  error: string | null;
  accessToken?: string | null;
  me?: Me | null;
}

const initialState: AuthState = {
  auth: null,
  loading: false,
  error: null,
  accessToken: null,
  me: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await saveupService.login({ username, password})
      if (response.status == 200) {
        const data = handleResponse(response);

        const auth : Auth = {
          id: data.id,
          isLoggedIn: true,
          refreshToken: data.refreshToken,
          expires: data.expires,
          role: data.role,
          token: data.token,
          userName: data.userName,
          isAAD: false,
          permissions: []
        };
        localStorage.setItem('auth', JSON.stringify(auth));

        const me = await saveupService.getUserInfo();
        
        // Update the auth object with properties from me
        auth.permissions = me.permissions;   
        localStorage.setItem('auth', JSON.stringify(auth));

        return data;
      }
      else if(response.status == 401) {
        return rejectWithValue('Felaktigt användarnamn eller lösenord.');
      }
      else {
        return rejectWithValue(response.statusText);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const setUserProfile = createAsyncThunk(
'auth/setUserProfile',
async ({ isAAD, expires } : { isAAD: boolean, expires : Date }, { rejectWithValue }) => {
  try {    
    const data : Me = await saveupService.getUserInfo();
    const auth : Auth = {
      id: data.id,
      isLoggedIn: true,
      refreshToken: null,
      expires: expires,
      role: data.role,
      token: null,
      userName: data.mail,
      isAAD: isAAD,
      permissions: data.permissions
    }
    localStorage.setItem('auth', JSON.stringify(auth))
    return { auth : auth, me: data};
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getCurrentUser = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue }) => {
    try {
      const data : Me  = await saveupService.getUserInfo();    
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Log out the user whether through Azure AD and MSAL or through custom token i localstorage.
export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const data = localStorage.getItem('auth');
      const auth : Auth  = data ? JSON.parse(data) : null;
      if(auth) {
        localStorage.removeItem('auth');
        return true;
      }
      else {
        return rejectWithValue("No authentication set.");
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    clearAccessToken: (state) => {
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.accessToken = action.payload.accessToken;
        state.auth = action.payload;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCurrentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.me = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(setUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setUserProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.me = action.payload.me;
        state.auth = action.payload.auth;
      })
      .addCase(setUserProfile.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.auth = null;
        state.me = null;
      })
      .addCase(logout.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setAccessToken, clearAccessToken } = authSlice.actions;
export default authSlice.reducer;
