import React from "react";
import { PendingReading, Verification } from "../../../interfaces/interfaces";
import { warn } from "console";
import CounterDeviationForm from "./CounterDeviationForm";
import { List } from "@mui/material";
import CounterDeviationTitle from "./CounterDeviationTitle";
import { Control } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
interface CountersDeviationsProps {
  counterId: number;
  index: number;
  pendingReading: PendingReading | undefined;
}
const CounterDeviations: React.FC<CountersDeviationsProps> = (
  props: CountersDeviationsProps
) => {
  const renderWarningsForCounterId = () => {
    const warnings = props.pendingReading?.warnings.map((warning, index) => {
      return (
        <CounterDeviationForm
          key={index}
          warning={warning}
          index={index}
          displayInputs={
            index ===
            (props.pendingReading && props.pendingReading.warnings.length - 1)
          }
        />
      );
    });
    return warnings;
  };

  if (props.pendingReading === undefined || props.pendingReading.warnings.length === 0) return <div></div>  
  else
    return (
      <List component="div" disablePadding>
        {renderWarningsForCounterId()}
      </List>
    );
};
export default CounterDeviations;
