import React from "react";
import Footer from "./Footer";
import { Grid2 } from "@mui/material";
const MenuFrame: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="general-container">
      <Grid2 container direction="column" alignContent="center">
        <Grid2>
          <div className="general-box">{children}</div>
        </Grid2>
        <Grid2>
          <Footer />
        </Grid2>
      </Grid2>
    </div>
  );
};
export default MenuFrame;
