import { Axios, AxiosResponse } from "axios";
import { CounterValue, CounterValueLogParameter, CounterValueParameter, CounterVerificationParameter, Estate, Login, Me, Result, User, Verification } from "../interfaces/interfaces";
import saveupClient, { handleResponse } from "./saveupClient";

async function login(data: Login) {
    return await saveupClient.get<AxiosResponse>(`/user/login?username=${data.username}&password=${data.password}`);
};
async function getUserEstates() {
    return await saveupClient.get<Estate[]>(`/user/estates`).then(handleResponse);
};
async function getEstate(estateId: number) {
    return await saveupClient.get<Estate>(`/estate/${estateId}/Full?all=false`).then(handleResponse);
}
async function getCounterValueExists(counterId: number, dateString: string) {
    return await saveupClient.get<boolean>(`/counter/value/exists?counterId=${counterId}&date=${dateString}`);
}
async function getCounterValueWarnings(counterVerificationParameter: CounterVerificationParameter) {
    return await saveupClient.post<Verification>(`/counter/verify/list`, counterVerificationParameter).then(handleResponse);
}
async function getCurrentUser() {
    return await saveupClient.get<User>(`user/me`).then(handleResponse)
   }
async function getUserInfo() {
    return await saveupClient.get<Me>(`user/me`).then(handleResponse)
   }
async function searchEstates(keyword: string) {
    const result = await saveupClient.post<Estate[]>(`/estate/search?keyword=${keyword}`).then(handleResponse);
    return result;
}
async function saveCounterValue(counterValueParameter: CounterValueParameter) {
    return await saveupClient.post<AxiosResponse>(`/counter/value/save`, counterValueParameter)
}
async function saveCounterValues(counterValueParameter: CounterValueParameter) {
    return await saveupClient.post<AxiosResponse>(`/counter/values/save`, counterValueParameter)
}
async function saveCounterValueLog(counterValueLogParameter: CounterValueLogParameter) {
    const htmlEncodedString = encodeURIComponent(counterValueLogParameter.text);
    return await saveupClient.post<AxiosResponse>(`/counter/value/log?counterValueId=${counterValueLogParameter.counterValueId}&text=${htmlEncodedString}&tag=${counterValueLogParameter.tag}`)
}
const saveupService = {
    login,
    getUserEstates,
    getEstate,
    getCounterValueExists,
    getCounterValueWarnings,
    getCurrentUser,
    getUserInfo,
    searchEstates,
    saveCounterValue,
    saveCounterValues,
    saveCounterValueLog
};
export default saveupService;