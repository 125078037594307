import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../redux/authSlice";
import { Cloud } from "@mui/icons-material";
import { Button } from "@mui/material";
import { loginRequest, tokenRequest } from "../../msalConfig";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AppDispatch } from "../../redux/store";
import { getAuthToken } from "../../helpers/authHelper";

export interface AADLoginButtonProps {
  loading: boolean;
}

const AADLoginButton: React.FC<AADLoginButtonProps> = ({ loading }) => {
  const { instance, } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  return (
    <Button
      variant="contained"
      fullWidth
      disabled={loading}
      className="button-cloud"
      style={{ backgroundColor: "#007FFF", color: "white" }}
      onClick={handleLogin}
    >
      <Cloud />
      &nbsp;Logga in med Microsoft Entra ID
    </Button>
  );
};

export default AADLoginButton;