import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import saveupService from '../api/saveupService';
import { Estate } from '../interfaces/interfaces';

// Define the initial state
interface FetchState<T> {
    data: T;
    loading: boolean;
    error: string | null;
  }
  
  interface EstatesState {
    fetchEstates: FetchState<Estate[]>;
    fetchEstate: FetchState<Estate | undefined>;
    searchEstates: FetchState<Estate[]>;
    selectedEstate: Estate | null;
  }
  
  const initialState: EstatesState = {
    fetchEstates: {
      data: [],
      loading: false,
      error: null,
    },
    fetchEstate: {
        data: undefined,
        loading: false,
        error: null,
    },
    searchEstates: {
      data: [],
      loading: false,
      error: null,
    },    
    selectedEstate: null,
  };

// Create an async thunk for fetching estates
export const fetchEstates = createAsyncThunk(
  'estates/fetchEstates',
  async (_, { rejectWithValue }) => {
    try {
      const estates = await saveupService.getUserEstates();

      estates.sort((a: Estate, b: Estate) => a.name.localeCompare(b.name));

      return estates;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Create an async thunk for searching estates
export const searchEstates = createAsyncThunk(
  'estates/searchEstates',
  async (keyword: string, { rejectWithValue }) => {
    try {
      const data = await saveupService.searchEstates(keyword);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for fetching an estate by id.

export const fetchEstate = createAsyncThunk(
    'estates/fetchEstate',
    async (estateId: number, { rejectWithValue }) => {
      try {
        const estate = await saveupService.getEstate(estateId);
        return estate;
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
);

const estatesSlice = createSlice({
    name: 'estates',
    initialState,
    reducers: {
      setSelectedEstate(state, action: PayloadAction<Estate | null>) {
        state.selectedEstate = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        // Fetch Estates
        .addCase(fetchEstates.pending, (state) => {
          state.fetchEstates.loading = true;
          state.fetchEstates.error = null;
        })
        .addCase(fetchEstates.fulfilled, (state, action: PayloadAction<Estate[]>) => {
          state.fetchEstates.loading = false;
          state.fetchEstates.data = action.payload;
        })
        .addCase(fetchEstates.rejected, (state, action: PayloadAction<any>) => {
          state.fetchEstates.loading = false;
          state.fetchEstates.error = action.payload;
        })
        // Search Estates
        .addCase(searchEstates.pending, (state) => {
          state.searchEstates.loading = true;
          state.searchEstates.error = null;
        })
        .addCase(searchEstates.fulfilled, (state, action: PayloadAction<Estate[]>) => {
          state.searchEstates.loading = false;
          state.searchEstates.data = action.payload;
        })
        .addCase(searchEstates.rejected, (state, action: PayloadAction<any>) => {
          state.searchEstates.loading = false;
          state.searchEstates.error = action.payload;
        })
        // Fetch estate by id
        .addCase(fetchEstate.pending, (state) => {
            state.fetchEstate.loading = true;
            state.fetchEstate.error = null;
        })
        .addCase(fetchEstate.fulfilled, (state, action: PayloadAction<Estate>) => {
            state.fetchEstate.loading = false;
            state.fetchEstate.data = action.payload;
        })
        .addCase(fetchEstate.rejected, (state, action: PayloadAction<any>) => {
            state.fetchEstate.loading = false;
            state.fetchEstate.error = action.payload;
        });
    },
  });
export const { setSelectedEstate } = estatesSlice.actions;
export default estatesSlice.reducer;