import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { Auth } from "../interfaces/interfaces";
import { Grid2, Link, Typography } from "@mui/material";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { logout } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const [userName, setUserName] = useState<string | null>(null);
  const currentUser = useSelector((state: RootState) => state.auth.me);
  const auth: Auth | null = useSelector((state: RootState) => state.auth.auth);
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.isLoggedIn) {
      if (currentUser) {
        setUserName(currentUser.mail ?? "");
      }
    }
    else {
      const localStorageAuth : Auth  = JSON.parse(localStorage.getItem("auth") ?? "{}");
      if(localStorageAuth?.isLoggedIn) {
        setUserName(localStorageAuth.userName);
      }
    }
  }, [auth, currentUser]);

  

  const handleOnLogOutClick = async () => {
    if(isAuthenticated) {
      const logoutResult = await dispatch(logout())
      if(logoutResult.meta.requestStatus === "fulfilled") {
        await instance.logout();
        navigate("/login");
      }    
    }
    else {
      const logoutResult = await dispatch(logout())
      if(logoutResult.meta.requestStatus === "fulfilled") {
        navigate("/login");
      }
    }
  };

  return (
    <footer className="footer-box">
      <Grid2 container justifyContent="space-between" alignItems="center">
        <Grid2>
          <Typography variant="caption">
            {userName ? `${userName}` : "Not logged in"}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography variant="caption">
            <Link href="#" onClick={handleOnLogOutClick} underline="none">
              Logga ut
            </Link>
          </Typography>
        </Grid2>
      </Grid2>
    </footer>
  );
};

export default Footer;
