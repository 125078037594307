import React from "react";
import {
  Electricity,
  Efficiency,
  ElectricityHeat,
  CoolingEnergy,
  CoolingFlow,
  HeatingEnergy,
  HeatingFlow,
  Time,
  Oil,
  Water,
  Temperature,
} from "../../icons/gomorron";
import { Recycling } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Counter } from "../../interfaces/interfaces";
export interface EstateViewTreeIconProps {
  counter: Counter;
  defaultValue: string;
}
const useStyles = makeStyles({
  saveupTreeIcon: {
    fontSize: "inherit",
    textRendering: "auto",    
    height: '2em',
    width: '2em', 
  },
});

const validTags = ["ELECTRICITY", "HEAT_EXCHANGER", "ELECTRICITY_HEAT", "COOLING_ENERGY", "COOLING_FLOW", "HEATING_ENERGY", "HEATING_FLOW", "TIME", "OIL", "WATER", "TEMPERATURE", "RECYCLING_ENERGY", "RECYCLING_FLOW"];

const EstateViewCounterTreeIcon: React.FC<EstateViewTreeIconProps> = (
  props: EstateViewTreeIconProps
) => {
  const classes = useStyles();

  const firstValidTag = props.counter.tags.find((tag) => validTags.includes(tag.name));
  const tagName = firstValidTag ? firstValidTag.name : "default";

  const renderIcon = () => {
    switch (tagName) {
      case "ELECTRICITY":
        return <Electricity className={classes.saveupTreeIcon} />;
      case "HEAT_EXCHANGER":
        return <Efficiency className={classes.saveupTreeIcon} />;
      case "ELECTRICITY_HEAT":
        return <ElectricityHeat className={classes.saveupTreeIcon} />;
      case "COOLING_ENERGY":
        return <CoolingEnergy className={classes.saveupTreeIcon} />;
      case "COOLING_FLOW":
        return <CoolingFlow className={classes.saveupTreeIcon} />;
      case "HEATING_ENERGY":
        return <HeatingEnergy className={classes.saveupTreeIcon} />;
      case "HEATING_FLOW":
        return <HeatingFlow className={classes.saveupTreeIcon} />;
      case "TIME":
        return <Time className={classes.saveupTreeIcon} />;
      case "OIL":
        return <Oil className={classes.saveupTreeIcon} />;
      case "WATER":
        return <Water className={classes.saveupTreeIcon} />;
      case "TEMPERATURE":
        return <Temperature className={classes.saveupTreeIcon} />;
      case "RECYCLING_ENERGY":
        return <Recycling />;
      case "RECYCLING_FLOW":
        return <Recycling />;
      default:
        return props.defaultValue;
    }
  };

  return <>{renderIcon()}</>;
};
export default EstateViewCounterTreeIcon;
