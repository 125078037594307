import { handleResponse } from "../api/saveupClient";
import saveupService from "../api/saveupService";
import { ReadingType } from "../enums/ReadingType";
import { ResultType } from "../enums/ResultType";
import { CounterValue, CounterValueLogParameter, CounterValueParameter, PendingReading, Reading, Result } from "../interfaces/interfaces";

/**
 * Creates a `CounterValueParameter` object based on the provided reading.
 *
 * @param reading - The reading object which can be of type `Reading` or `PendingReading`.
 * @returns A `CounterValueParameter` object containing the counter ID, current date, override status, and values.
 */
export const createCounterValueParameter = (
  reading: Reading | PendingReading
): CounterValueParameter => {
  const currentDateString = new Date().toISOString();
  const counterValueParameter: CounterValueParameter = {
    counterId: reading.id,
    date: currentDateString,
    override: reading.override === true,
  };

  if (reading.type === ReadingType.heating) {
    const { FROMAIR, OFFAIR, OUTAIR } = reading.values;
    if (
      FROMAIR !== undefined &&
      OFFAIR !== undefined &&
      OUTAIR !== undefined
    ) {
      if (FROMAIR !== 0 && OFFAIR !== 0 && OUTAIR !== 0) {
        counterValueParameter.values = [
          { value: FROMAIR, type: "FROMAIR" },
          { value: OFFAIR, type: "OFFAIR" },
          { value: OUTAIR, type: "OUTAIR" },
        ];
      }
    }
  } else {
    counterValueParameter.value =
      reading.type === ReadingType.default ? reading.values["val"] : 0;
  }

  return counterValueParameter;
};

/**
 * Handles the response from saving a counter value and processes any associated logs.
 *
 * @param response - The response object from the save operation.
 * @param reading - The reading object, which can be either a `Reading` or `PendingReading`.
 * @returns A promise that resolves to a `Result<CounterValue>` object containing the result of the save operation.
 */
export const handleSaveResponse = async (
  response: any,
  reading: Reading | PendingReading
): Promise<Result<CounterValue>> => {
  let resultString = "PENDING";
  const result: CounterValue = handleResponse(response);
  if (response.status === 200 && result && result.id > 0) {
    resultString = "OK";

    if ((reading as PendingReading).tag !== undefined) {
      const { comment, tag } = reading as PendingReading;
      if (tag && comment && comment.length > 0) {
        const counterValueLogParameter: CounterValueLogParameter = {
          counterValueId: result.id,
          text: comment,
          tag: tag,
        };
        const logResponse = await saveupService.saveCounterValueLog(counterValueLogParameter);
        const counterValueLog = handleResponse(logResponse);
        if (counterValueLog?.id === 0) {
          resultString = "LOG_ERROR";
        }
        else {
            result.counterValueLogs = [counterValueLog];
        }
      }
    }
  }

  return {
    type: ResultType.Ok,
    ResultString: resultString,
    Message: response.statusText,
    tag: result,
  };
};
