import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { LOG_ERROR, OK } from "../../shared/constants";
import { Counter, Estate } from "../../interfaces/interfaces";
import { getAllCounters } from "../../helpers/estateHelper";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { set } from "react-hook-form";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    marginTop: "2rem",
  },
  logo: {
    width: "150px",
    marginBottom: "1rem",
  },
  listGroup: {
    width: "100%",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  multiValueWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  errorAir: {
    borderColor: "red",
  },
  childListItem: {
    paddingLeft: "1rem",
    borderLeft: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  grandChildListItem: {
    paddingLeft: "0.5rem",
    paddingBottom: "0.5rem",
    paddingRight: "0.5rem",
    border: "1px solid #ddd",
    marginBottom: "0.5rem",
  },
  errorTextField: {
    backgroundColor: "#ffcccc",
  },
});

const SaveResultPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results, loading, error } = useSelector(
    (state: RootState) => state.counter.saveCounterValue
  );
  const [counters, setCounters] = useState<Counter[]>([]);
  const fetchedEstate = useSelector(
    (state: RootState) => state.estates.fetchEstate.data
  );
  useEffect(() => {
    if (fetchedEstate) {
      const allAddresses = fetchedEstate?.addresses || [];
      const allMeasurePoints = allAddresses.flatMap(
        (address) => address.measurePoints
      );
      const allCounters = allMeasurePoints.flatMap(
        (measurePoint) => measurePoint.counters
      );
      setCounters(allCounters);
    }
  }, [fetchedEstate, results]);

  const classes = useStyles();

  const generateCounterLabel = (counter: Counter | undefined) => {
    if (counter === undefined) return "undefined";
    return counter.name.length > 0 || counter.counterNumbers.length > 0
      ? `${counter.name} (${counter.counterNumbers.join(", ")})`
      : `Räkneverk (${counter.id})`;
  };

  const renderResult = () => {
    if (!results || results.length === 0) {
      return <Alert severity="error">Inga resultat att visa.</Alert>;
    }

    return (
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Räkneverk</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Värde</TableCell>
                <TableCell>Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((result, index) => {
                const currentCounterId = result.tag?.counterId;
                const currentCounter = counters.find(
                  (counter) => counter.id === currentCounterId
                );

                return (
                  <TableRow key={result.tag?.id || `error-${index}`}>
                    <TableCell>
                      {generateCounterLabel(currentCounter)}
                    </TableCell>
                    <TableCell>
                      {result.tag?.date.toLocaleString().split("T")[0]}
                    </TableCell>
                    <TableCell>{result.tag?.value}</TableCell>
                    <TableCell>
                      {result.ResultString === LOG_ERROR ? (
                        <Typography color="error">
                          Kommentaren kunde inte sparas.
                        </Typography>
                      ) : (
                        result.Message
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Typography variant="h5" gutterBottom>
          {results?.length > 0
            ? "Sparade följande värden:"
            : error
            ? "Mätarställningar kunde inte sparas. Vänligen kontrollera indata och kontakta support om problemet kvarstår."
            : "Tryck på tillbaka för att återgå till fastigheten."}
        </Typography>
        {results?.length > 0 && renderResult()}
        <Button
          variant="contained"
          color="primary"
          className="button-primary"
          fullWidth
          style={{ marginTop: "1rem" }}
          onClick={() =>
            fetchedEstate
              ? navigate(`/estate/${fetchedEstate.id}`)
              : navigate(`/estates`)
          }
        >
          Tillbaka
        </Button>
      </div>
    </div>
  );
};

export default SaveResultPage;
