import React, { useEffect } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Alert, Grid2 } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

interface AlreadyExistsMessageProps {
  counterId: number;
}

const AlreadyExistsMessage: React.FC<AlreadyExistsMessageProps> = (
  props: AlreadyExistsMessageProps
) => {
  return (
    <div>
      <Alert
        severity="warning"
        icon={<WarningAmber />}
        style={{ margin: "8px" }}>
            Det finns redan ett värde för räkneverket sparat för dagens datum.
        </Alert>
    </div>
  );
};
export default AlreadyExistsMessage;
