import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  Canceler,
} from "axios";
import msalInstance, { tokenRequest } from "../msalConfig";
const $baseURL: string = process.env.REACT_APP_API_URL || "/api"; // "https://api.saveup.se/api"/

const httpHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const config: AxiosRequestConfig = {
  baseURL: $baseURL,
  headers: httpHeaders,
  timeout: 10000,
  //withCredentials: true,
  responseType: "json",
  maxContentLength: 2000,
  maxBodyLength: 2000,
  maxRedirects: 5,
  cancelToken: new axios.CancelToken((cancel: Canceler) => []),
};

export const handleResponse = (response: AxiosResponse) => {
  return response?.data;
};

export const handleError = (error: AxiosError) => {
  if (error.response) {
    return error.response.status;
  } else {
    return error.message;
  }
};
export const useToken = () => {
  return JSON.parse(localStorage.getItem("auth")!).token || "";
};

const saveupClient: AxiosInstance = axios.create(config);

saveupClient.interceptors.request.use(
  async (config) => {
    if (config.headers) {

      let token : string | null = null

      // Ensure we've got an active account set.
      let account = msalInstance.getActiveAccount();
      if(!account) { 
        const accounts = msalInstance.getAllAccounts();
        if (accounts?.length > 0) {
          account = accounts[0];
        }
      }
  
      // Aquire the token silently if we have an account.
      if(account) { 
      try {
        const tokenResponse = await msalInstance.acquireTokenSilent({...tokenRequest, account: (account ? account : undefined)});
        if(tokenResponse) {
          token = tokenResponse.accessToken;
        }
      }
      catch (error) {
        console.error("Silent token acquisition failed: ", error);
      }
    }

    // If no token was aquired from MSAL, lets try fetching a locally issued token.
      if (!token) {
        let userAuth;
        try {
          const item = localStorage.getItem("auth")
          if(item) {
            userAuth = JSON.parse(localStorage.getItem("auth") ?? "");
          }
        } catch (e) {
          console.error("Error parsing auth data from localStorage: ", e);
        }
        if (userAuth) token = userAuth.token;
      }

      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      } else {
        delete config.headers.authorization;
      }
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

saveupClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      // Clear all tokens
      clearAllTokens();

      msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });

      window.location.href = '/login'
    }

    return Promise.reject(error);
  }
);
export default saveupClient;

const clearAllTokens = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("me");
};