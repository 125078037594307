import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  Alert,
  ListItemIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import saveupService from "../../api/saveupService";
import {
  fetchEstates,
  searchEstates,
  setSelectedEstate,
} from "../../redux/estateSlice";
import { Estate } from "../../interfaces/interfaces";
import "../../style/custom.css";
import { Search, Home } from "@mui/icons-material";

const Estates: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const estates: Estate[] = useSelector(
    (state: RootState) => state.estates.fetchEstates.data
  );
  const searchResult: Estate[] = useSelector(
    (state: RootState) => state.estates.searchEstates.data
  );
  const selectedEstate: Estate | null = useSelector(
    (state: RootState) => state.estates.selectedEstate
  );
  const [keyword, setKeyword] = useState<string>("");
  const [searchError, setSearchError] = useState<string | null>(null);
  const [locationInfo, setLocationInfo] = useState<string | null>(null);
  const [isCustomerRole, setIsCustomerRole] = useState<boolean>(
    auth ? auth.role.toLowerCase() === "customer" : false
  );
  const [debouncedSelectedEstate, setDebouncedSelectedEstate] = useState<Estate | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchEstates());
  }, [dispatch]);

  const handleSearch = () => {
    if (keyword.length > 3) {
      dispatch(searchEstates(keyword));
    }
  };

  useEffect(() => {
    if (auth) {
      setIsCustomerRole(auth.role.toLowerCase() === "customer");
      getMyLocation();
    }
  }, [auth]);

  useEffect(() => {
    if(selectedEstate) {
      if(estates.find((estate) => estate.id === selectedEstate.id)) {
        setDebouncedSelectedEstate(selectedEstate);
      }      
    }
  }, [selectedEstate]);

  const handleSelectEstate = (estateId: number) => {
    const estateById = estates.find((estate) => estate.id === estateId);
    if (estateById) {
      dispatch(setSelectedEstate(estateById));
    }
  };

  const handleSelectedEstateFromSearch = (estateId: number) => {
    const estateById = searchResult.find((estate) => estate.id === estateId);
    if (estateById) {
      dispatch(setSelectedEstate(estateById));
    }
  };


  const getMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocationInfo(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          console.error(error);
          setSearchError(error.message);
        }
      );
    } else {
      setSearchError("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" className="image-logo">
        <img src="img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
      </Box>
      <Typography variant="h5" gutterBottom>
        Mina fastigheter
      </Typography>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Select
          value={debouncedSelectedEstate ? debouncedSelectedEstate.id : ""}
          onChange={(e) => handleSelectEstate(Number(e.target.value))}
          fullWidth
        >
          {estates.map((estate) => (
            <MenuItem key={estate.id} value={estate.id}>
              <Home />
              &nbsp;{estate.name}
            </MenuItem>
          ))}
        </Select>
        {!isCustomerRole ? (
          <div>
            <TextField
              label="Sök fastighet..."
              variant="outlined"
              fullWidth
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={handleSearch}
              disabled={keyword.length <= 3}
              className="button-secondary"
              style={{ marginTop: "8px" }}
            >
              <Search />
              &nbsp;Sök
            </Button>
          </div>
        ) : (
          ""
        )}
        {locationInfo && <Alert severity="info">{locationInfo}</Alert>}
        {searchError && <Alert severity="error">{searchError}</Alert>}
        {!isCustomerRole ? (
          <div>
            <List
              sx={{
                display: searchResult.length > 0 ? "block" : "none",
              }}
            >
              {searchResult.map((estate) => (
                <ListItem
                  key={estate.id}
                  onClick={() => handleSelectedEstateFromSearch(estate.id)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#C7F7EB",
                    },
                    backgroundColor:
                      selectedEstate && selectedEstate.id === estate.id
                        ? "#8CEBBF"
                        : "inherit",
                  }}
                >
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <ListItemText primary={estate.name} />
                </ListItem>
              ))}
            </List>
          </div>
        ) : (
          ""
        )}
        <Button
          variant="contained"
          className="button-primary"
          fullWidth
          disabled={!selectedEstate}
          onClick={() =>
            selectedEstate && navigate(`/estate/${selectedEstate.id}`)
          }
        >
          Nästa
        </Button>
      </Box>
    </div>
  );
};

export default Estates;
