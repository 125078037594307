import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AAD_CLIENT_ID ?? "",
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_TENANT_ID ?? ""}`,
      redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL ?? "",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
          }
        },
        loggerlevel: 'Verbose'
      },
    }
  };
  
  export const loginRequest = {
    scopes: ["User.Read", `api://${process.env.REACT_APP_AAD_CLIENT_ID}/${process.env.REACT_APP_AAD_SAVEUP_SCOPE_NAME}`]
  };

  export const tokenRequest = {
    scopes: [`api://${process.env.REACT_APP_AAD_CLIENT_ID}/${process.env.REACT_APP_AAD_SAVEUP_SCOPE_NAME}` ]
  }
  const msalInstance = new PublicClientApplication(msalConfig);
  export default msalInstance;