import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { setUserProfile, login } from "../../redux/authSlice";
import { TextField, Button, Alert, Box } from "@mui/material";
import { RootState, AppDispatch } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import "../../style/custom.css"; // Import the custom CSS file
import AADLoginButton from "./AADLoginButton";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { tokenRequest } from "../../msalConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { Auth } from "../../interfaces/interfaces";
import jwtDecode from "jwt-decode";

interface IFormInput {
  username: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  const { loading, error } = useSelector((state: RootState) => state.auth);
  const { register, handleSubmit } = useForm<IFormInput>();

  const currentUser = useSelector((state: RootState) => state.auth.me);

  useEffect(() => {
    if (
      !isAuthenticated ||
      inProgress !== InteractionStatus.None ||
      accounts?.length === 0
    )
      return;

    const initializeToken = async () => {
      let accessToken: string | null = null;
      try {
        const tokenResponse = await instance.handleRedirectPromise();

        if (tokenResponse) {
          instance.setActiveAccount(tokenResponse.account);
          accessToken = tokenResponse.accessToken;
        }
      } catch (error) {
        console.log("Error handling token redirect.", error);
      }

      if (!accessToken) {
        try {
          const response = await instance.acquireTokenSilent(tokenRequest);
          accessToken = response.accessToken;
        } catch (error) {
          console.error("Silent token acquisition failed: ", error);
        }

        if (!accessToken) {
          try {
            await instance.acquireTokenRedirect(tokenRequest);
          } catch (error) {
            console.error("Redirect token acquisition failed.", error);
          }
        }
      }
      if (isAuthenticated && accessToken) {
        const decoded: any = jwtDecode(accessToken);
        dispatch(
          setUserProfile({ isAAD: true, expires: new Date(decoded.exp * 1000) })
        );
      }
    };

    initializeToken();
  }, [isAuthenticated, inProgress, instance, dispatch]);

  useEffect(() => {
    const auth: Auth = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth") as string)
      : null;
    if (auth?.isAAD && isAuthenticated && currentUser) {
      navigate("/estates");
    }
    if (!auth?.isAAD && currentUser) {
      navigate("/estates");
    }
  }, [isAuthenticated, currentUser]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const loginResponse = await dispatch(login(data));
    if (loginResponse.meta.requestStatus === "fulfilled") {
      if (
        !loginResponse.payload.isAAD &&
        loginResponse.payload.token
      ) {
        navigate("/estates");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <Box display="flex" justifyContent="center" className="image-logo">
          <img src="img/saveup.logo.svg" alt="SaveUp Logo" className="logo" />
        </Box>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Användarnamn"
            variant="outlined"
            fullWidth
            {...register("username", { required: true })}
          />
          <TextField
            label="Lösenord"
            variant="outlined"
            type="password"
            fullWidth
            {...register("password", { required: true })}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            className="button-primary"
          >
            Logga in
          </Button>
          <AADLoginButton loading={loading} />
          {error && <Alert severity="error">{error}</Alert>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
