import { Navigate, Outlet } from "react-router-dom";
import { isAuthorized } from "../helpers/authHelper";
import { useIsAuthenticated } from "@azure/msal-react";
import { Auth } from "../interfaces/interfaces";
import MenuFrame from "./MenuFrame";
const PrivateRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const auth: Auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth") as string)
    : null;

  if (!auth) {
    return <Navigate to="/login" />;
  }

  if (auth.isAAD && (!isAuthenticated || !isAuthorized())) {
    return <Navigate to="/login" />;
  }

  if (!auth.isAAD && !isAuthorized()) {
    return <Navigate to="/login" />;
  }

  return (
    <MenuFrame>
      <Outlet />
    </MenuFrame>
  );
};

export default PrivateRoutes;
