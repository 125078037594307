import { Address, Counter, Estate, MeasurePoint } from "../interfaces/interfaces";

export const getAllCounters = (estate: Estate): Counter[] => {
    let counters: Counter[] = [];
    let measurePoints : MeasurePoint[] = [];
  
    const extractCountersFromMeasurePoints = (measurePoints: MeasurePoint[]) => {
      measurePoints.forEach((measurePoint) => {
        counters = counters.concat(measurePoint.counters);
      });
    };

    const extractMeasurePointsFromAddresses = (addresses: Address[]) => {
        addresses.forEach((address) => {
          measurePoints = measurePoints.concat(address.measurePoints);
        });
      };
    
    if(estate.addresses) {
      // Extract measurepoints from addresses
      extractMeasurePointsFromAddresses(estate.addresses);
    
      // Extract counters from measurePoints under each address
      extractCountersFromMeasurePoints(measurePoints);

    }
    return counters;
  };